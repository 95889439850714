import React from 'react';
import './main.css';
import first from './7Pictures/1.jpeg'
import second from './7Pictures/2.JPG'

function FirstCategory() {
  const images = [
    first,
    second,
  ];

  return (
    <div className="firstCategory">
      <span className="popupTitle"><u>Mais qui sommes-nous ?</u></span>
      <span className='popupSubTitle'>
        <b>
          30 ans de passion et d'engagement dans l'art floral, "Un matin de mai..." l'univers végétal et floral de tous vos projets.
        </b>
      </span>
      <div className="popupContent">
        {images.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
      <span className='popupSubTitle'>
        <b>Daniella & Patrick, la même passion de la fleur !</b>
      </span>
    </div>
  );
}

export default FirstCategory;
