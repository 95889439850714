import React from 'react';
import './main.css';
import first from './4Pictures/1.JPG';
import second from './4Pictures/2.JPG';
import third from './4Pictures/3.JPG';

function FirstCategory() {
  const images = [
    first,
    second,
    third,
  ];

  return (
    <div className="firstCategory">
      <span className="popupTitle">Services aux entreprises</span>
      <span className='popupSubTitle'>
        Votre partenaire et conseil idéal pour végétaliser vos locaux professionnels, votre accueil, vos séminaires, vos évènements ou votre communication. La touche florale et végétale de votre entreprise.
        <br/>
        Quelques références,
        <br/>
        Présentation à la presse automobile mondiale du nouveau Audi Q7, congrès de la chambre notariale d'Alsace Moselle, inauguration nouveau Hall Endress+Hauser, végétalisation cabinet d'avocats, cabinet médical, soirée de gala de la Sté Budendorff, etc...
        <br/>
        Quelques lieux d'intervention de grands décors,
        <br/>
        Le petit Wetto, la cité de l'auto, la cité du train, l'auberge du zoo, le domaine du Kaegy, l'hôtel du parc,
        <br/>
        le Kinépolis, etc...
      </span>
      <div className="popupContent">
        {images.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default FirstCategory;
