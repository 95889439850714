import React from 'react';
import './main.css';
import first from './3Pictures/PHOTO-2024-02-05-18-51-21.jpg'
import second from './3Pictures/IMG_0886.jpg'
import third from './3Pictures/IMG_0411.png'
import fourth from './3Pictures/nwPresta1.jpeg'
import five from './3Pictures/nwPresta2.jpeg'
import six from './3Pictures/nwPresta3.jpeg'

function FirstCategory() {
  const images = [
    first,
    second,
    third,
    fourth,
    five,
    six,
  ];

  return (
    <div className="firstCategory">
      <span className="popupTitle"><u>Nos prestations</u></span>
      <span className='popupSubTitle' >
        <h3>Particuliers</h3>
        - Abonnement floral. Carte cadeau. la fête des mères, un anniversaire, noel, un mariage, un remerciement ou toute autre occasion, à offrir ou se faire plaisir tout au long de l'année au rythme qui vous plaît.
        <br/><br/>
        - Mariage. Vous accompagner et vous conseiller pour créer l'univers floral qui embellira ce temps unique.
        <br/><br/>
        - Evènementiel. Un jour particulier, anniversaire, fête de famille... pour sublimer vos moments précieux de la vie.
        <br/><br/>
        - Agencement de terrasses, balcons, jardins, végétalisation de vos lieux de vie. Univers végétal.
        <br/><br/>
        - Vous accompagner lors... d'un deuil, mais aussi l'entretien de sépulture. Abonnement pour fleurir et entretenir vos tombes familiales.
      </span>
      <div className="popupContent">
        {images.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default FirstCategory;
