import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Home from './Home/home.js';
import Gallerie from './Gallerie/gallerie.js';
import Contact from './Contact/contact.js';
import { Howler } from 'howler';
import backgroundSong from './backgroundSong.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setTimeout(() => {
      setShowPopup(showPopup);
    }, 2000);
  };

  const toggleAudio = () => {
    if (audioPlaying) {
      audioRef.current.pause();
      setAudioPlaying(false);
    } else {
      audioRef.current.play();
      setAudioPlaying(true);
    }
  };

  useEffect(() => {
    document.cookie = 'CONSENT=SameSite=None;Secure';
    document.cookie = 'HSID=SameSite=None;Secure';
    document.cookie = 'SSID=SameSite=None;Secure';
    document.cookie = 'APISID=SameSite=None;Secure';
    document.cookie = 'SAPISID=SameSite=None;Secure';
    document.cookie = '__Secure-1PAPISID=SameSite=None;Secure';
    document.cookie = 'SID=SameSite=None;Secure';
    document.cookie = '__Secure-1PSID=SameSite=None;Secure';
    document.cookie = 'OTZ=SameSite=None;Secure';
    document.cookie = 'S=SameSite=None;Secure';
    document.cookie = '1P_JAR=SameSite=None;Secure';
    document.cookie = '__Secure-1PSIDTS=SameSite=None;Secure';
    document.cookie = 'SIDCC=SameSite=None;Secure';
    document.cookie = '__Secure-1PSIDCC=SameSite=None;Secure';

    Howler.mobileAutoEnable = true;
  }, []);

  return (
    <div className="App">
      <div className='SongButton' onClick={toggleAudio}>
        {audioPlaying ? (
          <FontAwesomeIcon icon={faVolumeUp} />
        ) : (
          <FontAwesomeIcon icon={faVolumeXmark} className='muted' />
        )}
      </div>
      <Home />
      <Gallerie />
      <Contact />
      <div className='footer'>
        <div>
          2024 © {" "}
          <a
            style={{
              textDecoration: "none",
              color: "#ece9d4",
              fontSize: "1.2rem",
              cursor: "cell",
            }}
            href="https://oscar-dev.fr"
            target="_blank" rel="noopener noreferrer"
          >
            oscar-dev
          </a>
        </div>
        <div>
          <span style={{ textDecoration: "none", color: '#ece9d4', fontSize: '1.2rem', cursor: 'cell' }} onClick={togglePopup}>Mentions légales</span>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <h3>Mentions Légales</h3>
          <br/><br/>
          <span>Un Matin De Mai..., 2 Rue Emilio Noelting, 68100 Mulhouse</span>
          <br/><br/>
          <span>SIRET: 33995587400021</span>
          <br/>
          <span>Responsable de la publication: Oscar FRANK</span>
          <br/>
          <span>Hébergeur: LWS | 2 Rue Jules Ferry, 88190 Golbey | +33 1 77 62 30 03</span>
        </div>
      )}
      <audio ref={audioRef} src={backgroundSong} loop />
    </div>
  );
}

export default App;

// Oscar FRANK | Entrepreneur individuel

// SIRET: 95360945000010

// Siège social: 14 rue de Bourgogne, 68100 Mulhouse

// Mail: contact@oscar-dev.fr

// Téléphone: +33 6 51 32 23 65

// Responsable de la publication: Oscar FRANK

// Hébergeur: LWS | 2 Rue Jules Ferry, 88190 Golbey | +33 1 77 62 30 03
