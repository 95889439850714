import React from 'react';
import './main.css';
// import planteInterieur from './2Pictures/planteInterieur.jpg';
// import planteExterieur from './2Pictures/planteExterieur.jpg';
// import bouquetMariage from './2Pictures/bouquetMariage.jpg';
// import decoFlorale from './2Pictures/decoFlorale.jpg';
// import oeuvreArt from './2Pictures/oeuvreArt.jpg';
// import miseScene from './2Pictures/miseScene.jpg';
// import livraison from './2Pictures/livraison.jpg';
// import conseils from './2Pictures/conseils.jpg';
import first from './2Pictures/IMG_0133.jpg'
import second from './2Pictures/IMG_0459.jpg'
import third from './2Pictures/IMG_0469.jpg'
import four from './2Pictures/PHOTO-2024-02-05-18-52-18.jpg'
import five from './2Pictures/IMG_0507.jpg'
import six from './2Pictures/PHOTO-2024-02-05-18-40-47.jpg'
import seven from './2Pictures/nwAube1.jpeg'
import eight from './2Pictures/PHOTO-2024-02-05-18-41-55.jpg'

function FirstCategory() {
  const images = [
    first,
    second,
    third,
    four,
    five,
    six,
    seven,
    eight,
  ];

  return (
    <div className="firstCategory">
      <span className="popupTitle"><u>De l'aube au crépuscule</u></span>
      <span className='popupSubTitle' >
        <i>
          ...l'expression d'une émotion fugitive, d'un sentiment poétique...
          <br/>
          Donner ou créer une impression tout en évoquant une émotion... Issa 1763-1828
        </i>
        <br/><br/>
        Les fleurs accompagnent, célèbrent, consolent, embellissent toute notre vie.
      </span>
      <div className="popupContent">
        {images.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default FirstCategory;
