import React from 'react';
import './main.css';
import first from './5Pictures/IMG_1012.JPG'
import second from './5Pictures/IMG_1013.JPG'
import third from './5Pictures/IMG_1014.JPG'
import fourth from './5Pictures/IMG_1015.JPG'

import five from './5Pictures/IMG_1011.JPG'

import six from './5Pictures/IMG_1020.JPG'
import seven from './5Pictures/IMG_1021.jpg'

import eight from './5Pictures/IMG_1010.JPG'

import nine from './5Pictures/IMG_1016.jpg'
import ten from './5Pictures/IMG_1017.jpg'
import eleven from './5Pictures/IMG_1018.jpg'
import twelve from './5Pictures/IMG_1019.jpg'

import img6 from './5Pictures/img6.jpg'

function FirstCategory() {
  const images = [
    first,
    second,
    third,
    fourth,
  ];

  const images2 = [
    five,
  ];

  const images3 = [
    six,
    seven,
  ];

  const images4 = [
    eight
  ];

  const images5 = [
    nine,
    ten,
    eleven,
    twelve,
  ];

  const images6 = [
    img6,
  ];

  return (
    <div className="firstCategory">
      <span className="popupTitle"><u>Nos partenaires</u></span>
      <span className='popupSubTitle' >
        <h3><u>Räder "design stories"</u></h3>
        <i>Ce sont nos différences qui font notre richesse, voilà les Histoires que nous racontons.</i>
        <br/>
        <h4>Histoires</h4>
        Des produits qui font naître un sourire, qui évoquent des souvenirs de moments particuliers ou qui donnent un petit quelque chose de plus au quotidien, c'est cela, Räder.
        <br/>
        Des produits qui sont la quintéscence même des cadeaux qui viennent du coeur, un voyage dans le monde d'histoires design.
      </span>
      <div className="popupContent">
        {images.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

      <span className='popupSubTitle' >
        <h3><u>Sompex "design for life"</u></h3>
        Un design de luminaires passionnés et inspirés avec l'amour du détail.
        <br/>
        Une gamme de luminaires innovants pour l'intérieur et l'extérieur, ce n'est pas seulement l'espace de vie qui est éclairé, mais c'est aussi toute la vie !
      </span>
      <div className="popupContent">
        {images2.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

      <span className='popupSubTitle' >
        <h3><u>The Munio</u></h3>
        <i>Munio en latin, signifie : fortifier, protéger, préserver, défendre.</i>
        <br/>
        Une collection de bougies et cires parfumées décorées à la main avec des fleurs, des plantes, des prairies Lettones. L'atelier The Munio utilise de la cire végétale 100% éco-responsable et 50% plus durable.
      </span>
      <div className="popupContent">
        {images3.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

      <span className='popupSubTitle' >
        <h3><u>Terre d'Oc</u></h3>
        <br/>
        Qu'il s'agisse d'un thé, d'une bougie, d'une eau parfumée ou d'un mélange d'huiles essentielles, des produits créés de façon artisanale en Haute-Provence, inspirées par les voyages d'ici & d'ailleurs.
        <br/>
        Des produits pour votre bien-être avec des ingrédients 100% bio ou naturels, des emballages éco-conçus, le tout dans une démarche éthique, toujours.
      </span>
      <div className="popupContent">
        {images4.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

      <span className='popupSubTitle' >
        <h3><u>Terres cuites de Toscane</u></h3>
        <i>Poterie 100% artisanale de Toscane</i>
        <br/>
        Avec chaque objet de notre collection Tuscany Terracotta, vous faites l'expérience de l'élément le plus fondamental : LA TERRE.
        <br/>
        Si vous touchez un pot, vous ressentez la rugosité de la terre, sa texture, sa force. Si vous la regardez, vous voyez toutes les nuances de cette terre et vous voyez de minuscules pierres contenues dans l'argile, qui rendent cette collection extrêmement résistante. Une terre cuite qui résiste au gel jusqu'à -35°C. Chaque pièce de cette collection est un objet intemporel qui donne vie à nos balcons, terrasses et jardins tout au long de l'année.
      </span>
      <div className="popupContent">
        {images5.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

      <span className='popupSubTitle' >
        <h3><u>Ferme fleurettes</u></h3>
        <br/>
        Floricultrices de la couronne mulhousienne, pour vous proposer des fleurs locales de saison six mois dans l'année. Merci à Julie et Lucile.
      </span>
      <div className="popupContent">
        {images6.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>

    </div>
  );
}

export default FirstCategory;
