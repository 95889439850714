import React from 'react';
import './main.css';
import first from './6Pictures/IMG_0167.png'
import second from './6Pictures/IMG_0440.png'
import third from './6Pictures/IMG_1022.png'

function FirstCategory() {
  const images = [
    first,
    second,
    third,
  ];

  return (
    <div className="firstCategory">
      <span className="popupTitle"><u>La galerie d'art</u></span>
      <span className='popupSubTitle' >
        Dans l'élégance sobre des compositions florales se cotoient les oeuvres d'artistes. Ainsi au fil du temps les abstractions acryliques de Jacques Guiot, les sculptures du monde poétique de Marc Sparza, ou encore les totems inox de Leon Callas, les céramiques primitves de Rémy Bonhert, les femmes aux corps céramiques imprimés d'Anita Perez, les incroyables mosaiques de Gérard Brand, se sont invités comme une évidence dans ce lieu pour sublimer la fleur.
        <br/>Une démarche d'esthétisme et de beauté.
        <br/><br/>
        <h4><u>Exposition 2022</u></h4>
        Le monde poétique, philosophique, drôle et parfois étrange de Marc Sparza.
        <h4><u>Automne 2024</u></h4>
        Exposition photos, Bleu Garance. Photographe intuitive.
      </span>
      <div className="popupContent">
        {images.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default FirstCategory;
