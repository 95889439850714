import React from 'react';
import './main.css';
import first from './1Pictures/1.JPG'
import second from './1Pictures/2.JPG'
import third from './1Pictures/3.JPG'
import fourth from './1Pictures/nwSf1.jpeg'
import five from './1Pictures/nwSf2.jpeg'
import six from './1Pictures/nwSf3.jpeg'

function FirstCategory() {
  const images = [
    first,
    second,
    third,
    fourth,
    five,
    six,
  ];

  return (
    <div className="firstCategory">
      <span className="popupTitle"><u>Notre savoir faire</u></span>
      <span className='popupSubTitle' >
        Situé au pied du Rebberg, niché derrière le parc Tivoli la boutique de Patrick et Daniella Figenwald est à la fois atelier de créations florales et galerie d'art.
        <br/>
        Notre passion et notre engagement est de sublimer la fleur avec simplicité et sobriété. Plus de 30 ans de passion, de formations en nouvelles tendances, en grand décor, en évenementiel ou nous restons fidèles à notre engagement du premier jour, à savoir vous proposer avant tout une fleur de grande qualité dans le respect de l'environnement et des saisons, en privilégiant autant que possible les circuits courts et nos productions locales, florales et horticoles.
        <br/><br/>
        Nous prenons à coeur les grandes problématiques écologiques du monde d'aujourd'hui et sommes heureux de partager notre passion de la fleur, le merveilleux de la fleur à travers notre univers.
        <br/><br/>
      </span>
      <span className='popupSubTitle2'>
        <i>Ouvrir les yeux sur le vivant et le merveilleux des fleurs.</i>
      </span>
      <div className="popupContent">
        {images.map((image, index) => (
          <div
            key={index}
            className="square"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default FirstCategory;
