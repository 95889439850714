import './home.css';
import React, { useState, useEffect } from 'react';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

function Home() {
  const [textVisible, setTextVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTextVisible(true);
    }, 1500);
  }, []);

  const handleAddressClick = (event) => {
    event.preventDefault();
    window.open('https://www.google.com/maps/search/?api=1&query=2+Rue+Emilio+Noelting,+68100+Mulhouse', '_blank');
  };

  return (
    <div className="Home">
      <div className={`mainTitlePageHome ${textVisible ? 'mainTitlePageHomeVisible' : ''}`}>Un matin de mai...</div>
      <div className={`mainTitlePageHome2 ${textVisible ? 'mainTitlePageHomeVisible2' : ''}`}>l'émotion de l'éphémère</div>
      <div className={`blurSquareHome ${textVisible ? 'blurSquareHomeVisible' : ''}`}>
        <div className={`texteHomePageHome ${textVisible ? 'texteHomePageHomeVisible' : ''}`}>
          <span>
            Du Mardi au Vendredi : 9h15 - 12h30 & 14h - 19h <br />
            Samedi : 9h15 - 19h <br />
            Dimanche & jours feriés : 9h - 12h30 <br />
            fermé le lundi <br/>
            ------------------------ <br />
            <a href="https://www.google.com/maps/search/?api=1&query=2+Rue+Emilio+Noelting,+68100+Mulhouse" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#ccbcaf' }} onClick={handleAddressClick}>
              Parc Tivoli, <br/>
              2 Rue Emilio Noelting<br/> 68100 Mulhouse <br />
            </a>
            ------------------------ <br />
            <a style={{textDecoration: 'none', color: '#ccbcaf'}} href="tel:+3389540262">03 89 54 02 62</a> <br />
            ------------------------ <br />
            <div className="socialIcons">
              <a href="https://www.facebook.com/Unmatindemai/" target="_blank" rel="noopener noreferrer">
                <FaFacebookSquare className="facebookIcon" />
              </a>
              <a href="https://www.instagram.com/unmatindemai/" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="instagramIcon" />
              </a>
            </div>
          </span>
        </div>
      </div>
      {/* <div className={`buttonSeeProducts ${textVisible ? 'buttonSeeProductsVisible' : ''}`} onClick={handleScroll}>
        Découvrir nos produits
      </div> */}
    </div>
  );
}

export default Home;
