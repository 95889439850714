import './contact.css';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

function Contact() {
  return (
    <div className="Contact">
      <span className="mainTitlePage">Nous contacter</span>
      <span className='infocontact'>
        <a href="https://www.google.com/maps/search/?api=1&query=2+Rue+Emilio+Noelting,+68100+Mulhouse" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#ccbcaf' }}>
            Du Mardi au Vendredi : 9h15 - 12h30 & 14h - 19h <br />
            Samedi : 9h15 - 19h <br />
            Dimanche & jours feriés : 9h - 12h30 <br />
            fermé le lundi <br/></a>
        <a href="https://www.google.com/maps/search/?api=1&query=2+Rue+Emilio+Noelting,+68100+Mulhouse" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#ccbcaf' }}>------------------------ <br />Parc Tivoli<br/>2 Rue Emilio Noelting<br/>68100 Mulhouse<br/>------------------------</a>
        <br /> <a style={{textDecoration: 'none', color: '#ccbcaf'}} href="tel:+33389540262">03 89 54 02 62<br/>------------------------ <br /></a>
        <div className="socialIcons" style={{marginBottom:'20px'}}>
          <a href="https://www.facebook.com/Unmatindemai/" target="_blank" rel="noopener noreferrer">
            <FaFacebookSquare className="facebookIcon" />
          </a>
          <a href="https://www.instagram.com/unmatindemai/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="instagramIcon" />
          </a>
        </div>
      </span>
    </div>
  );
}

export default Contact;
