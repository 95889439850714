import React, { useState, useEffect, useRef } from 'react';
import './gallerie.css';
import FirstPopUp from './ProductsPages/1';
import SecondPopUp from './ProductsPages/2';
import ThirdPopUp from './ProductsPages/3';
import FourthPopUp from './ProductsPages/4';
import FifthPopUp from './ProductsPages/5';
import SixthPopUp from './ProductsPages/6';
import SeventhPopUp from './ProductsPages/7';

import conseilfleursvegetaux from "../1ETE/secondBkg.png";
import quisommesnous from '../1ETE/quiSommesNous.jpeg';
import nosexclus from "../1ETE/nosexclus.jpeg";
import notresavoirfaire from "../1ETE/notresavoirfaire.jpeg";
import delaubeaucrepuscume from "../1ETE/delaubeaucrepuscume.jpeg";
import nosprestations from "../1ETE/nosprestations.jpeg";
import livraisonserveur from "../1ETE/livraisonserveur.jpeg";
import abofloral from "../1ETE/abofloral.png";
import accompagnementperso from "../1ETE/accompagnementperso.png";
import galerieArt from '../1ETE/IMG_0440.png';
import { Modal } from '@mui/material';

const cards = [
  { text: 'Notre savoir-faire', imageUrl: notresavoirfaire },
  { text: 'De l\'aube au crépuscule', imageUrl: delaubeaucrepuscume },
  { text: 'Nos prestations', imageUrl: nosprestations },
  { text: 'Services aux entreprises', imageUrl: conseilfleursvegetaux },
  { text: 'Nos partenaires', imageUrl: nosexclus },
  { text: 'La galerie d\'art', imageUrl: galerieArt },
  { text: 'Mais qui sommes-nous ?', imageUrl: quisommesnous },
];

const cards2 = [
  { text: 'Livraison à domicile. Mulhouse & environs.', imageUrl: livraisonserveur },
  { text: 'Carte cadeau. Abonnement floral.', imageUrl: abofloral },
  { text: 'Accompagnement personnalisé', imageUrl: accompagnementperso },
];

function Gallerie() {
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);

  const handleClose = () => setIsPopupOpen(false);

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
    setIsPopupOpen(true);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupOpen(false);
      }
    };

    if (isPopupOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isPopupOpen]);

  return (
    <div className="Gallerie">
      <span className="mainTitlePage">Notre carte de visite</span>
      <div className="produits">
        <div className="productWrapper">
          {cards.map((card, index) => (
            <div
              className={`product ${selectedCardIndex === index ? 'selected' : ''}`}
              key={index}
              onClick={() => handleCardClick(index)}
              style={{ backgroundImage: `url(${card.imageUrl})` }}
            >
              <p className="productText">{card.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="Gallerie2">
        <span className="mainTitlePage2">...mais aussi...</span>
        <div className="services">
          <div className="productWrapper2">
            {cards2.map((card, index) => (
              <div
                className="product2 card2"
                key={index}
                style={{ backgroundImage: `url(${card.imageUrl})` }}
              >
                <div className="overlay"></div>
                <p className="productText2">{card.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isPopupOpen && <div className="popupContainer">
        <Modal
          open={isPopupOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="popup" ref={popupRef}>
            <div className='closePopUpButton' onClick={handleClose}>
              X
            </div>
            {selectedCardIndex === 0 && <FirstPopUp />}
            {selectedCardIndex === 1 && <SecondPopUp />}
            {selectedCardIndex === 2 && <ThirdPopUp />}
            {selectedCardIndex === 3 && <FourthPopUp />}
            {selectedCardIndex === 4 && <FifthPopUp />}
            {selectedCardIndex === 5 && <SixthPopUp />}
            {selectedCardIndex === 6 && <SeventhPopUp />}
          </div>
        </Modal>
      </div>
      }
    </div>
  );
}

export default Gallerie;
